import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import FullPageLoader from "./FullPage.jsx";
// Soft UI Dashboard React examples
import TimelineList from "components/Timeline/TimelineList";
import TimelineItem from "components/Timeline/TimelineItem";
import SuiTagInput from "components/SuiTagInput/SuiTagInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import { makeHttpDownloadRequest } from "utils/httpServices";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import SoftBadge from "components/SoftBadge";
import { OpenInNew } from "@mui/icons-material";
import { makeHttpRecieptDownloadRequest } from "utils/httpServices";
import axios from "axios";
import { Carousel, Image } from "antd";
export default function ViewAppointment() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewContent, setReviewContent] = useState("");
  const [acceptRejectDialog, setAcceptRejectDialog] = useState(false);
  const [status, setStatus] = useState("not_visited");
  const [isActionLoading, setActionLoading] = useState(false);
  const tags = ["Teeth Whitening", "Filling", "Dental Bridge"];
  const navigate = useNavigate();
  const fileUrl = "https://example.com/path-to-your-file";

  const getData = async () => {
    try {
      const resp = await makeHttpRequest("get", `viewAppointment/${id}`);
      console.log(resp?.data[0]);
      setStatus(resp?.data[0]?.is_visited);
      setData(resp?.data[0]);
    } catch (error) {
      // toast.error('Network error.');
    }
  };

  const formatDateTime = (utcDateString) => {
    if (!utcDateString) {
      return { formattedDate: "", formattedTime: "" };
    }

    const utcDate = new Date(utcDateString);

    // Add 5 hours and 30 minutes
    utcDate.setUTCHours(utcDate.getUTCHours());
    utcDate.setUTCMinutes(utcDate.getUTCMinutes());

    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = utcDate.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = utcDate.toLocaleTimeString("en-US", {
      hour12: false,
    });
    // console.log(formattedDate);

    return { formattedDate, formattedTime };
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const handleDownload = async () => {
    if (data) {
      if (!data?.receipt_uploaded_by_user) {
        toast.error("It does not contain any receipt.");
        return;
      }
      const urls = data?.receipt_uploaded_by_user;
      urls.forEach(async (url) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_IMAGE_URL}receipt/${encodeURIComponent(
              url
            )}`,
            {
              method: "GET",
              headers: {
                  "Cache-Control": "no-cache, no-store, must-revalidate",
                  "Pragma": "no-cache",
                  "Expires": "0"
              }}
          );
          const blob = await response.blob();

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading file:", error);
          toast.error("Network error.");
        }
      });
    }
  };
  const handleVerifyInvoice = async () => {
    setActionLoading(true);
    try {
      const obj = {
        appointment_id: data?._id,
        status: confirmModal,
      };
      console.log("Paylod from action", obj);
      const res = await makeHttpRequest("post", "verifyInvoice", obj, {});
      console.log(res);
      if (res?.code == 200) {
        toast.success(res?.message || "Verify Invoice API to be called");
        getData();
      } else {
        toast.error(res?.message || "Verification Failed");
      }

      setConfirmModal(null);
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Verification Failed");
    }
    setActionLoading(false);
  };
  const handleReviewInvoice = async () => {
    if (!reviewContent) {
      toast.error("Please enter message");
      return;
    }
    setActionLoading(true);
    try {
      const obj = {
        appointment_id: data?._id,
        invoice_rejection_reason: "" + reviewContent,
      };
      const res = await makeHttpRequest(
        "put",
        "invoiceRejectionReason",
        obj,
        {}
      );
      if (res?.code == 200) {
        toast.success(res?.message || "Sent Successfully");
        setReviewContent("");
        getData();
      } else {
        toast.error(res?.message || "Verification Failed");
      }

      setReviewModal(null);
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Verification Failed");
    }
    setActionLoading(false);
  };

  const [confirmModal, setConfirmModal] = useState(null);
  async function sendRequestStatus(value) {
    const body = {
      request_id: id,
      action: value,
    };

    try {
      const resp = await makeHttpRequest(
        "post",
        "acceptOrRejectChargeRequest",
        body
      );
      getData();
      setAcceptRejectDialog(false);
    } catch (error) {}
  }
  return (
    <>
      {isActionLoading && <FullPageLoader />}
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox py={3}>
          <SoftBox mb={3}>
            <Card>
              <SoftBox p={3}>
                <SoftBox
                  mb={3}
                  component="form"
                  role="form"
                  className="appointment_form"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SoftTypography variant="h6">
                      Patient Details
                    </SoftTypography>
                    {data?.request_not_to_charge?.action == "pending" && (
                      <SoftButton
                        color="info"
                        onClick={() => {
                          setAcceptRejectDialog(true);
                        }}
                        size="small"
                      >
                        Approve/Reject 'No Charge' Request
                      </SoftButton>
                    )}
                    {/* {(data?.request_not_to_charge?.action == "accepted" || data?.request_not_to_charge?.action == "rejected") && <h5 style={{fontSize:"14px"}}>Billing Status : <SoftBadge color={data?.request_not_to_charge?.action == "accepted" ? "success" : "error"} badgeContent={data?.request_not_to_charge?.action == "accepted" ? "Accepted" : "Rejected"} size="lg" ></SoftBadge> </h5>} */}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            First Name <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          value={data?.user_details?.first_name}
                          placeholder="First Name"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Last Name <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          value={data?.user_details?.last_name}
                          placeholder="Last Name"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Mobile Number <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="tel"
                          value={data?.user_details?.phone_number}
                          placeholder="Number"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Email ID <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="email"
                          value={data?.user_details?.email}
                          placeholder="email"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox
                  component="form"
                  role="form"
                  className="appointment_form"
                >
                  <SoftTypography variant="h6">
                    Appointment Details
                  </SoftTypography>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12} lg={4} xl={4}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Date of Appointment{" "}
                            <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="date"
                          value={formatDateTime(data?.start_time).formattedDate}
                          disabled
                        />
                        {/* {console.log("data?.start_time", formatDateTime(data?.start_time).formattedDate)} */}
                      </SoftBox>
                    </Grid>
                    <Grid item md={4} xs={12} lg={4} xl={4}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            From <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="time"
                          value={formatDateTime(data?.start_time).formattedTime}
                          placeholder="Start Time"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={4} xs={12} lg={4} xl={4}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            To <span className="text-danger">*</span>
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="time"
                          value={formatDateTime(data?.end_time).formattedTime}
                          placeholder="End Time"
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Consulting Doctor
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          value={data?.dentist_details?.full_name}
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox className="user_treatment">
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Treatment
                          </SoftTypography>
                        </SoftBox>
                        <SuiTagInput
                          tags={data?.questionires?.service?.map((val) => val)}
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Source
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          type="text"
                          value={data?.landing_page?.platform_type || "-"}
                          disabled
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item md={6} xs={12} lg={6} xl={6}>
                      {" "}
                      {data?.request_not_to_charge?.action && (
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Request Not Be Charged Status
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            value={
                              data?.request_not_to_charge?.action
                                ?.slice(0, 1)
                                ?.toUpperCase() +
                                data?.request_not_to_charge?.action?.slice(1) ||
                              "-"
                            }
                            disabled
                          />
                        </SoftBox>
                      )}
                    </Grid>
                    <Grid item md={4} xs={12} lg={4} xl={4}>
                      <SoftBox
                        style={{ position: "relative" }}
                        className="user_treatment"
                      >
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Receipt Uploaded by User
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Carousel arrows>
                            {data?.receipt_uploaded_by_user?.length > 0 ? (
                              data?.receipt_uploaded_by_user?.map(
                                (file, index) => {
                                  const fileUrl = `${
                                    process.env.REACT_APP_IMAGE_URL
                                  }receipt/${encodeURIComponent(file)}`;
                                  return (
                                    <div key={index}>
                                      {file?.toLowerCase().endsWith(".pdf") ? (
                                        <iframe
                                          title={`File Preview ${index}`}
                                          src={fileUrl}
                                          width="100%"
                                          height="200px"
                                          style={{ border: "none" }}
                                        />
                                      ) : (
                                        <Image
                                          width="100%"
                                          height={200}
                                          style={{ objectFit: "contain" }}
                                          src={fileUrl}
                                          preview={false}
                                          alt={`Uploaded File ${index}`}
                                        />
                                      )}
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div>
                                <Image
                                  width="100%"
                                  height={200}
                                  style={{ objectFit: "contain" }}
                                  preview={false}
                                  src={process.env.REACT_APP_DEFAULT_IMAGE_URL}
                                  alt="Default Preview"
                                />
                              </div>
                            )}
                          </Carousel>

                          {/* <iframe
                            title="File Preview"
                            src={
                              data?.receipt_uploaded_by_user
                                ? `${
                                    process.env.REACT_APP_IMAGE_URL
                                  }receipt/${encodeURIComponent(
                                    data?.receipt_uploaded_by_user
                                  )}`
                                : process.env.REACT_APP_DEFAULT_IMAGE_URL
                            }
                            width="100%"
                            height="200px"
                          /> */}
                          {/* <SoftButton className="download-btn" variant="small" color="secondary" >Download</SoftButton>
                           */}
                          {data?.receipt_uploaded_by_user &&
                            data?.receipt_uploaded_by_user?.length > 0 && (
                              <SoftButton
                                className="download-btn"
                                variant="small"
                                color="secondary"
                                onClick={handleDownload}
                                style={{ zIndex: 0 }}
                                // disabled={fileUrl === ''}
                              >
                                Download
                              </SoftButton>
                            )}
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: "8px",
                      }}
                      item
                      xs={12}
                      md={6}
                    >
                      <SoftBox display="flex" justifyContent="start" pt={4}>
                        <FormControl>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Status
                          </SoftTypography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // defaultValue={status==="visited"?"Active":"Deactive"}
                            size="small"
                            value={
                              status === "visited"
                                ? "Active"
                                : status === "not visited"
                                ? "Deactive"
                                : ""
                            }
                          >
                            <FormControlLabel
                              value="Active"
                              control={<Radio />}
                              label="Visited"
                              disabled
                            />
                            <FormControlLabel
                              value="Deactive"
                              control={<Radio />}
                              label="Not Visited"
                              disabled
                            />
                          </RadioGroup>
                        </FormControl>
                      </SoftBox>
                      {data?.status !== "rejected" &&
                        data?.isInvoiceVerified == "pending" &&
                        data?.receipt_uploaded_by_user && (
                          <>
                            <div style={{ display: "flex", gap: "8px" }}>
                              <ConfirmModal
                                isOpen={confirmModal !== null}
                                onClose={() => setConfirmModal(null)}
                                messageText={`Are you sure you want to ${confirmModal} the invoice? This action cannot be undone.`}
                                onConfirm={handleVerifyInvoice}
                              />
                              <SoftButton
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  setConfirmModal("approve");
                                }}
                              >
                                Approve
                              </SoftButton>
                              <Button
                                variant="contained"
                                color="error"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setConfirmModal("reject");
                                }}
                              >
                                Reject
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ color: "white" }}
                                onClick={() => {
                                  setReviewModal(true);
                                }}
                              >
                                Request For Additional Info
                              </Button>
                            </div>
                          </>
                        )}
                      {data &&
                        data != {} &&
                        data?.isInvoiceVerified != "pending" && (
                          <h5 style={{ color: "gray" }}>
                            Invoice Verified by{" "}
                            {data?.invoice_verified_by?.name} at{" "}
                            {new Date(
                              data?.isInvoiceVerifiedDate
                            ).toLocaleString()}
                          </h5>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>

                    <Grid item md={12} xs={12} lg={12}>
                      <SoftBox>
                        <SoftBox mb={1} ml={0.5}>
                          <TimelineList title="User Preference">
                            <TimelineItem
                              color="info"
                              number="1"
                              title="What is the main purpose of your visit?"
                              // dateTime="22 DEC 7:20 PM"
                              badges={[data?.questionires?.main_purpose]}
                            />
                            <TimelineItem
                              color="success"
                              number="2"
                              title="What specific procedure are you looking to have?"
                              // dateTime="21 DEC 11 PM"
                              badges={data?.questionires?.service?.map(
                                (val) => val
                              )}
                            />
                            <TimelineItem
                              number="3"
                              title="What I'm looking for in a dentist?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={data?.questionires?.looking_in_dentist?.map(
                                (val) => val
                              )}
                            />
                            <TimelineItem
                              number="4"
                              title="When was the last time you saw a dentist?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={[data?.questionires?.last_time]}
                            />
                            <TimelineItem
                              number="5"
                              title="What time works best for your visit?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={
                                data?.questionires?.best_visit_time?.map(
                                  (val) => val
                                )
                                // [data?.questionires?.best_visit_time]
                              }
                              // {[data?.questionires?.best_visit_time]}
                            />
                            {/* <TimelineItem
                                                        number="6"
                                                        title="What time for your visit works best for you"
                                                        dateTime="21 DEC 9:34 PM"
                                                        badges={["Morning 8am-12pm"]}

                                                    /> */}
                            <TimelineItem
                              number="7"
                              title="How soon would you like to receive care?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={[data?.questionires?.receive_care]}
                            />
                            <TimelineItem
                              number="8"
                              title="Do you have dental insurance?"
                              // dateTime="21 DEC 9:34 PM"
                              badges={[
                                data?.questionires?.have_insurance
                                  ? "Yes i have Insurance"
                                  : "No I don't have insurance",
                              ]}
                            />
                            {data?.questionires?.have_insurance && (
                              <TimelineItem
                                number="9"
                                title="Who is your dental insurance provider?"
                                // dateTime="21 DEC 9:34 PM"
                                badges={[data?.questionires?.insurance]}
                                lastItem
                              />
                            )}
                          </TimelineList>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                pb={3}
              >
                <SoftButton
                  style={{ marginRight: "10px" }}
                  component={Link}
                  to="/appointmentlist"
                  variant="outlined"
                  color="info"
                >
                  Back
                </SoftButton>
              </SoftBox>
            </Card>
          </SoftBox>
        </SoftBox>
        <Modal
          open={reviewModal}
          onClose={() => setReviewModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter Message
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  rows={8}
                  value={reviewContent}
                  onChange={(e) => setReviewContent(e.target.value)}
                  placeholder="Enter Message"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                ></textarea>
              </div>
            </Typography>
            <div
              className="d-flex justify-content-end gap-3 mt-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                color: "white",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                size="small"
                style={{ color: "white" }}
                onClick={() => setReviewModal(false)}
              >
                Close
              </Button>
              <Button
                variant="contained"
                size="small"
                style={{ color: "white" }}
                onClick={handleReviewInvoice}
              >
                Send Request
              </Button>
            </div>
          </Box>
        </Modal>
        <Dialog
          open={acceptRejectDialog}
          onClose={() => {
            setAcceptRejectDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              minWidth: "600px",
              minHeight: "30px",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Payment status</DialogTitle>
          <DialogContent>
            <DialogContentText id="provider_container">
              <SoftBox>
                <SoftBox>
                  <SoftTypography variant="h6" style={{ margin: "0 0 1rem 0" }}>
                    Clinic has requested not to be charged for the appointment
                    due to the following reasons.
                  </SoftTypography>
                </SoftBox>
                <SoftBox>
                  <SoftTypography variant="h6">Reason :</SoftTypography>
                  <SoftTypography variant="h6">
                    {data?.request_not_to_charge?.comment}
                  </SoftTypography>
                </SoftBox>
                {data?.request_not_to_charge?.document && (
                  <SoftBox>
                    <SoftTypography
                      variant="h6"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_IMAGE_URL}documents/${data?.request_not_to_charge?.document}`,
                          "_blank"
                        );
                      }}
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                      className=" d-flex align-items-center"
                    >
                      View Attachment <OpenInNew fontSize="30px" />
                    </SoftTypography>
                  </SoftBox>
                )}
              </SoftBox>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SoftBox
              display="flex"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <SoftButton
                variant="outlined"
                style={{ marginRight: "10px" }}
                size="small"
                color="info"
                onClick={() => sendRequestStatus("rejected")}
              >
                Reject
              </SoftButton>
              <SoftBox>
                <SoftButton
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  size="small"
                  color="info"
                  onClick={() => {
                    sendRequestStatus("accepted");
                  }}
                >
                  Accept
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </DialogActions>
        </Dialog>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}
