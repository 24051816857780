import "./FullPage.css"

export default function FullPageLoader() {
  return (
    <div style={{width:"100vw",height:"100vh",position:"fixed",top:0,zIndex:9999999,pointerEvents:"auto",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor: "rgba(0, 0, 0, 0.4)",
        
        }}>

    <div className="loader mb-5">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      
      
    </div>
    </div>
  );
}
