import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import SoftBadge from "components/SoftBadge";

import UserTableData from "./data/UserTableData";
import { Link, useLocation } from "react-router-dom";
import SoftInput from "components/SoftInput";
import SuiSelect from "components/SuiSelect/SuiSelect";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import team2 from "assets/images/team-2.jpg";
import SoftAvatar from "components/SoftAvatar";
import DatePicker from "react-datepicker";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Modal } from "react-bootstrap";
import SoftButton from "components/SoftButton";

import Pagination from "../pagination/index";
import { CiSquareQuestion } from "react-icons/ci";

export default function RewardList() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open1, setOpen1] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(10);
  const [limit, setLimit] = useState(10);

  const [requestData, setRequestData] = useState();
  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const { columns } = UserTableData;
  const [data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [clinicLists, setClinicLists] = useState([]);
  const [branchList, steBranchList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [inpText, setInpText] = useState("");

  const formatDateTime = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDateString = utcDate.toLocaleDateString();
    const localTimeString = utcDate.toLocaleTimeString();

    return { formattedDate: localDateString, formattedTime: localTimeString };
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    console.log(`Selected page: ${value}`);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  function Author({ image, name, email }) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  }

  const gteLists = async () => {
    try {
      const resp = await makeHttpRequest("get", "clinicList");
      // console.log('resp?.data[0]');
      setClinicLists(resp?.data);
      // const resp1 = await makeHttpRequest('get', )
    } catch (error) {
      // toast.error('Network error.')
    }
  };
  console.log(clinicLists, "clinicList");



  const getData = async () => {
    console.log(inpText, "asdsjashhj");
    try {
      const offset = currentPage * rowsPerPage;
     
      const resp = await makeHttpRequest(
        "get",
        "getAllLandingPages",
        null,
      );
      console.log("resp-------->", resp);
      setTotalCount(resp?.count);
      const count1 = Math.ceil(resp?.count / 10);
      setTotalPage(count1);
      setRow(
        resp?.data.map((val) => {
          return {
           "Offer Title": (
            <SoftTypography
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {val?.offer_title}
          </SoftTypography>
            ),
            "Platform Type": (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.platform_type}
              </SoftTypography>
            ),

            "Offer Description": (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.offer_description}
              </SoftTypography>
            ),

            "Email Subject": (
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.email_subject}
              </SoftTypography>
            ),
            
            
            "Amount": (
              <>
                <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {val?.offer_amount}
              </SoftTypography>

                {/* <CiSquareQuestion/> */}
              </>
            ),
         
            // "Payment Status": val?.charge ? (
            //   <SoftTypography
            //     className="bookingPaymentSt"
            //     variant="caption"
            //     color="secondary"
            //     fontWeight="medium"
            //   >
            //     Charged{" "}
            //     <ErrorOutlineIcon
            //       onClick={() => requestHandler(val?.charge?._id)}
            //     />
            //   </SoftTypography>
            // ) : null,
            action: (
              <SoftBox>
                <SoftTypography
                  component={Link}
                  to={`/landingPage/${val?._id}`}
                  href="#"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                  marginRight="10px"
                >
                  Edit
                </SoftTypography>
                <SoftTypography
                  component={Link}
                  onClick={()=>{val?.platform_type == "default" ? navigator.clipboard.writeText("https://dentistfinder.ai") : navigator.clipboard.writeText("https://dentistfinder.ai?source="+val?._id);toast.success("Link Copied to Clipboard")}}
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                  marginRight="10px"
                >
                  Copy
                </SoftTypography>

                {/* <SoftTypography
                  component={Link}
                  to={`/editAppointment/${val?._id}`}
                  href="#"
                  variant="caption"
                  color="secondary"
                  fontWeight="medium"
                >
                  Edit
                </SoftTypography> */}
              </SoftBox>
            ),
          };
        })
      );
    } catch (error) {
      // toast.error('Network error.');
      // console.log(error)
    }
  };

  useEffect(() => {
    const x = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(x);
  }, [
    inpText,
    year,
    month,
    selectedClinic,
    selectedBranch,
    selectedProvider,
    currentPage,
    limit,
  ]);

  useEffect(() => {
    gteLists();
  }, []);

  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox p={3}>
            <Grid container spacing={2} alignItems="center">
              <SoftBox style={{display:"flex",gap:"8px"}}>
                <SoftTypography variant="h6">Landing Page List</SoftTypography>
               
                <Grid />
              </SoftBox>
           
            </Grid>
          </SoftBox>
          <Card>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={row} />
              
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>

     
    </DashboardLayout>
  );
}
