import {
    Button,
    Card, FormControlLabel, Grid, Switch, TablePagination
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";

import SoftInput from "components/SoftInput";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from 'moment';

export default function AppointmentList() {

    const columns = [
        { name: "Clinic", align: "left" },
        { name: "Email", align: "left" },
        { name: "Price Per Patient", align: "left" },
        { name: "Status", align: "left" },
        { name: "Amount", align: "center" },
        { name: "Total Appointment", align: "center" },
        { name: "Start Date", align: "center" },
        { name: "End Date", align: "center" },
        { name: "Due Date", align: "center" },
        { name: "Action", align: "center" },
    ]

    const [status, setStatus] = useState("pending");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(100);
    const [row, setRow] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        // previous month
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    );
    const [month, setMonth] = useState(selectedDate.getMonth() + 1);
    const [year, setYear] = useState(selectedDate.getFullYear());
    const [inpText, setInpText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    }

    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };

    const getList = async () => {
        try {
            const resp = await makeHttpRequest("get", "get-invoices", {}, {
                page: currentPage + 1,
                limit: rowsPerPage,
                month: month,
                year: year,
                search: inpText,
                status: status
            })
            const temp = resp?.invoices?.map((item) => {
                return {
                    Clinic: item?.branch_id?.practice_name || item?.branch_id?.full_name,
                    Email: item?.branch_id?.email,
                    "Price Per Patient": item?.branch_id?.price_per_appointment,
                    Status: item?.status,
                    Amount: `$${item?.total_amount}`,
                    "Total Appointment": item?.total_appointment,
                    "Start Date": String(moment(item?.start_time).format("DD-MM-YYYY")),
                    "End Date": String(moment(item?.end_time).format("DD-MM-YYYY")),
                    "Due Date": String(moment(item?.due_date).format("DD-MM-YYYY")),
                    Action: (<SoftBox>
                        <SoftTypography
                            component={Link}
                            to={`${item?._id}`}
                            href="#"
                            variant="caption"
                            color="secondary"
                            fontWeight="medium"
                            marginRight="10px"
                        >
                            View
                        </SoftTypography>
                    </SoftBox>)
                }
            })
            setRow(temp);
            setTotalPage(resp?.total);
        } catch (error) {
            console.log("error from server:", error)
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    const revertBill = async () => {
        try {
            await makeHttpRequest("get", "revert-invoices").then((res) => {
                toast.success(res?.message || "Bill reverted successfully");
                getList();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const generateBill = async () => {
        try {
            await makeHttpRequest("post", "generate-invoice").then((res) => {
                toast.success(res?.message || "Bill reverted successfully");
                getList();
            })
        } catch (error) {
            console.error(error)
        }
    }

    const deductPayment = async () => {
        try {
            await makeHttpRequest("post", "deduct-payment").then((res) => {
                toast.success(res?.message || "Bill Deducted successfully");
                getList();
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getList();
    }, [currentPage, rowsPerPage, month, year , status, inpText]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox p={3}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={2}>
                                <SoftTypography variant="h6">Billing Cycle List</SoftTypography>
                                <Grid />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <SoftBox
                                    className="table_header"
                                    display="flex"
                                    flexWrap="wrap"
                                    alignItems="center"
                                >
                                    <SoftBox className="filter_box">
                                        <SoftInput
                                            type="text"
                                            style={{ minWidth: "150px" }}
                                            className="form-control"
                                            placeholder="Search here"
                                            value={inpText}
                                            onChange={(e) => {
                                                setInpText(e.target.value);
                                            }}
                                        />
                                    </SoftBox>
                                    <div className="" style={{
                                    }}>
                                        <DatePicker
                                            selected={selectedDate}
                                            renderMonthContent={renderMonthContent}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                            onChange={(e) => {
                                                setSelectedDate(e);
                                                setMonth(e.getMonth() + 1);
                                                setYear(e.getFullYear());
                                            }}
                                            maxDate={
                                                new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
                                            }
                                            style={{
                                                maxWidth: "100px"
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        gap: "10px"
                                    }}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={status === "pending"}
                                                onChange={() => {
                                                    setStatus(status === "pending" ? "paid" : "pending")
                                                }}
                                            />}
                                            label={status === "pending" ? "Pending" : "Paid"}
                                            labelPlacement="end"
                                        />
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{ color: "white" }}
                                            onClick={generateBill}
                                        >
                                            Generate Bill
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{ color: "white" }}
                                            onClick={deductPayment}
                                        >
                                            Deduct Payment
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{ color: "white" }}
                                            onClick={revertBill}
                                        >
                                            Revert Bill
                                        </Button>
                                    </div>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </SoftBox>
                    <Card>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={row} />
                            <TablePagination
                                component="div"
                                count={totalPage}
                                page={currentPage}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                            />
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
        </DashboardLayout >
    );
}
