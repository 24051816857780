import { Dashboard } from "@mui/icons-material";
import { Card, Grid, MenuItem, Switch ,Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SuiSelect from "components/SuiSelect/SuiSelect";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { IoMdCloseCircle } from "react-icons/io";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SoftTextArea from "components/SoftTextArea/SoftTextArea";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import Doctor01 from "../../assets/images/team-1.jpg";
import Doctor02 from "../../assets/images/team-2.jpg";
import Doctor03 from "../../assets/images/team-3.jpg";
import DialogProviders from "components/DialogBox/DialogProviders";
import DefaultProjectCard from "./ProjectCards/DefaultProjectCard";
import { useFormik } from "formik";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { validateYupSchema } from "formik";
import DialogBranches from "components/DialogBox/DialogBranches";
import branch01 from "assets/images/clinicImgs.jpg";
import branch02 from "assets/images/clininc.jpg";
import branch03 from "assets/images/clinic03.jpg";
import debounce from "lodash.debounce";
import DefaultProjectCard1 from "./ProjectCards/DefaultProjectCard1";
import axios from "axios";
import { makeHttpGoogleMapRequest } from "utils/httpServices";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Services from "components/Services/Services";
import InsuranceList from "components/Insurances/Insurances";
import { generateHoursOptions } from "utils/common";
// import { Select } from "@mui/base";

export default function AddClinicManagement() {
  const libraries = ["places"];
  const searchBoxRefStreet = useRef(null);
  const [modernRating, setModernRating] = useState("high");
  const [flexibilityRating, setFlexibilityRating] = useState("high");
  const navigate = useNavigate();
  const [branchListing, setBranchListing] = useState([]);
  const [selector, setSelector] = useState("");
  const [inp, setInp] = useState("");
  const [checked1, setChecked1] = useState(true);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [checked, setChecked] = React.useState(true);
  const [imageArray, setImageArray] = useState([]);
  const [getServicesData, setGetServicesData] = useState([]);
  const [getInsurancesData, setGetInsurancesData] = useState([]);
  const [clinicId, setClinicId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClinic, setSelectedClinic] = useState("");
  const [validLocation, setValidLocation] = useState(false);

  const [name, setName] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clinicLogo, setCliniclogo] = useState(null);
  const [clinicLogoPath, setCliniclogoPath] = useState(null);

  const [clinicImage, setClinicImage] = useState(null);

  const [branchData, setBranchData] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [geoLocation, setGeoLoaction] = useState({ lng: 0, lat: 0 });

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    formik.setFieldValue("enabled[${index}]", event.target.checked);
  };

  // Callback for when files are dropped
  const onDrop = useCallback(
    (acceptedFiles) => {
      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      const nonImageFiles = acceptedFiles.filter(
        (file) => !file.type.startsWith("image/")
      );

      // Alert if non-image files are selected
      if (nonImageFiles.length > 0) {
        toast.error("All Non image files Will be ignored");
      }

      const updatedFiles = selectedFiles.concat(
        imageFiles.slice(0, 6 - selectedFiles.length)
      );
    
      setSelectedFiles(updatedFiles);
    },
    [selectedFiles]
  );

  const [workFieldCount, setWorkFieldCount] = useState([
    {
      id: 1,
      day: "",
      start_time: "date with time",
      end_time: "date with time",
    },
  ]);

  const removeImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  // react-dropzone hook configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
    maxFiles: 6,
  });

  // ---steps---/

  const [currentStep, setCurrentStep] = useState(1);
  const [ProviderListing, setProviderListing] = useState([]);


  const handleNext = async () => {
    // await formik.validateForm();

    // if(currentStep === 1){

    // formik.validateForm(formik.values).then((errors) => {
    //   // Set touched for all fields
    //   formik.setTouched({
    //     practice_name: true,
    //     full_name: true,
    //     phone_number: true,
    //     email: true,
    //     bio: true,
    //     address: true,
    //   });

    //   console.log(selectedFiles.length, selectedFiles)
    //   // If there are no errors, move to the next step
    //   if (Object.keys(errors).length === 0 && selectedFiles.length !== 0) {
    setCurrentStep(currentStep + 1);
    //   } else {
    //     toast.info("Fill all the Required Field.")
    //   }
    // });}else{
    //   setCurrentStep(currentStep + 1);
    // }
  };

  const handleBack = () => {
    if (currentStep === 2) {
      navigate("/clinicManagement");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  // --Add-Provider-Modal--/

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    handleOpen();
    const body = { ...values };

    const body1 = JSON.parse(JSON.stringify(body));

    // console.log(clinicLogoPath)
    // console.log(clinicImage, selectedFiles.length)
    let logo = clinicLogoPath;
    if (
      !formik.values.practice_name ||
      !formik.values.full_name ||
      !formik.values.phone_number ||
      !formik.values.email ||
      !formik.values.bio ||
      !formik.values.address ||
      !clinicLogoPath ||
      !clinicImage ||
      selectedFiles.length === 0 ||
      services.length === 0 ||
      insurancess.length === 0
    ) {
      handleClose();
      toast.info("All fields are mandatory.");
      return;
    }
    // console.log(body1.working_hours,"======================body1.working_")
    // for (let i = 0; i < body1.working_hours.length; i++) {
    //   const day = body1.working_hours[i].day;
    //   const startTime = body1.working_hours[i].start_time;
    //   const endTime = body1.working_hours[i].end_time;
    //   const enabled = body1.working_hours[i].enabled;

    //   if (!day || !startTime || !endTime || enabled === null) {
    //     toast.error("All fields are mandatory.");
    //     handleClose();
    //     return;
    //   }

    //   const [starhour, startminute] = startTime.split(":");
    //   const [endHour, endMinute] = endTime.split(":");
    //   let time = 0;
    //   starhour === "00" ? (time = 24) : (time = starhour);
    //   // console.log(starhour)

    //   if (time > endHour) {
    //     if (time >= 1 && time <= 12 && endHour >= 1 && endHour <= 12) {
    //       toast.error(
    //         "Invalid working hours. Start time must be before end time."
    //       );
    //       handleClose();
    //       return;
    //     } else if (time >= 13 && time <= 24 && endHour >= 13 && endHour <= 24) {
    //       toast.error(
    //         "Invalid working hours. Start time must be before end time."
    //       );
    //       handleClose();
    //       return;
    //     }
    //   } else if (time === endHour && startminute >= endMinute) {
    //     handleClose();
    //     toast.error(
    //       "Invalid working hours. Start time must be before end time."
    //     );
    //     return;
    //   }

    //   const startInputTime = new Date("2023-01-01");
    //   const endInputTime = new Date("2023-01-01");

    //   startInputTime.setHours(starhour, 0, 0, 0); // Set the hours and minutes
    //   endInputTime.setHours(endHour, 0, 0, 0); // Set the hours and minutes

    //   // const timeZoneOffset = startInputTime.getTimezoneOffset();

    //   // // Adjust the start time to UTC by subtracting the offset
    //   // const startUtcTime = new Date(startInputTime.getTime() - timeZoneOffset * 60000);
    //   // const endUtcTime = new Date(endInputTime.getTime() - timeZoneOffset * 60000);

    //   body1.working_hours[i].start_time = startInputTime.toISOString();
    //   body1.working_hours[i].end_time = endInputTime.toISOString();
    // }
    for (let i = 0; i < body1.working_hours.length; i++) {
      const day = body1.working_hours[i].day;
      const startTime = body1.working_hours[i].start_time;
      const endTime = body1.working_hours[i].end_time;
      const enabled = body1.working_hours[i].enabled;

      if (!day || !startTime || !endTime || enabled === null) {
        toast.error("All fields are mandatory.");
        handleClose();
        return;
      }

      const [starhour, startminute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      if (starhour === endHour && startminute >= endMinute) {
        toast.error(
          "Invalid working hours. Start time must be before end time."
        );
        handleClose();
        return;
      } else if (
        starhour > endHour ||
        (starhour === endHour && startminute > endMinute)
      ) {
        toast.error(
          "Invalid working hours. Start time must be before end time."
        );
        handleClose();
        return;
      }

      const startInputTime = new Date();
      const endInputTime = new Date();

      startInputTime.setUTCHours(0, 0, 0, 0);
      endInputTime.setUTCHours(0, 0, 0, 0);

      startInputTime.setUTCHours(starhour, startminute, 0, 0);
      endInputTime.setUTCHours(endHour, endMinute, 0, 0);

      const startUtcTime = new Date(
        startInputTime.getTime() + startInputTime.getTimezoneOffset() * 60000
      );
      const endUtcTime = new Date(
        endInputTime.getTime() + endInputTime.getTimezoneOffset() * 60000
      );

      body1.working_hours[i].start_time = startUtcTime.toISOString();
      body1.working_hours[i].end_time = endUtcTime.toISOString();
    }
    if (clinicLogoPath) {
      body.clinic_image = clinicLogoPath;
      body1.clinic_image = clinicLogoPath;
    }
    if (clinicImage) {
      body.profile_image = clinicImage;
      body1.profile_image = clinicImage;
    }
    try {
      if (selectedFiles.length == 0) {
        toast.error("Fill all the Required Field.");
        handleClose();
        return;
      }

      // const encodedAddress = encodeURIComponent(body.address);
      // const Resp1 = await makeHttpGoogleMapRequest(encodedAddress);
      // console.log("Resp1------------->", Resp1);
      // if (Resp1?.status == "ZERO_RESULTS") {
      //   toast.error("Please add a valid address.");
      //   return;
      // }
      // const longitude = Resp1?.results[0].geometry.location.lng;
      // const latitude = Resp1?.results[0].geometry.location.lat;
      if (validLocation === false) {
        toast.error("Please add right address.");
        handleClose();
        return;
      }
      const location = {
        type: "Point",
        coordinates: [geoLocation?.lng, geoLocation?.lat],
      };

      body1.location = location;

      const email_exist = await makeHttpRequest(
        "post",
        "checkEmailAvailability",
        { email: body.email }
      );

      // console.log(email_exist);
      if (email_exist) {
        toast.error("Email is already exist");
        handleClose();
        return;
      }

      // console.log(selectedFiles);

      const formData = new FormData();
      formData.append("path", "clinicDocument");
      selectedFiles.forEach((file, index) => {
        formData.append(`media`, file);
      });

      const Resp = await makeHttpRequest(
        "post",
        "uploadAdminMultipleMedia",
        formData
      );
      // console.log(Resp?.path);

      if (Resp) {
        body.clinic_documents = Resp?.path;
        body1.clinic_documents = Resp?.path;
      }

      body1.insurance_they_accept = insurancess;
      body1.services = services;
      body1.modern = modernRating;
      body1.flexibility = flexibilityRating;

      const resp = await makeHttpRequest("post", "addClinic", body1);
      // console.log(resp);
      setClinicId(resp?._id);
      toast.success("Added successfully.");
      setCurrentStep(currentStep + 1);
      handleClose();
      // navigate('/clinicManagement')
    } catch (error) {
      // console.log(error);
      // toast.error(error?.response?.data?.msg);
      handleClose();
      console.log(error);
    }
  };
  console.log(flexibilityRating);
  console.log(modernRating);
  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places?.length === 0) {
        // No valid places found
        // console.log("Invalid address entered");
       
        return;
      }

      const loc = places[0].formatted_address;
      console.log(places);

      // Assuming that having at least one place means a valid address
      // You may add more validation based on your requirements
      if (isValidPlace(places[0])) {
        formik.setFieldValue("address", loc);
        setGeoLoaction({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng(),
        });
        setValidLocation(true);
      } else {
        console.log("Invalid address entered");
        toast.error("Please enter valid address.");
        setValidLocation(false);
      }
    });
  };

  const isValidPlace = (place) => {
    // Example validation: Check if the place has a valid formatted address

    return (
      place && place.formatted_address && place.formatted_address.trim() !== ""
    );
  };

  // const locationHandler = () => {
  //   if (validLocation) {
  //     localStorage.setItem("lat", details.lat);
  //     localStorage.setItem("lng", details.lng);
  //   } else {
  //     toast.error("Please add valid address.");
  //   }
  // };

  const validationSchema = Yup.object().shape({
    practice_name: Yup.string().required("Practice Name is required"),
    full_name: Yup.string().required("Owner Name is required"),
    phone_number: Yup.number()
      .required("Phone Number is required")
      .typeError("Phone Number must be a valid number")
      .positive("Phone Number must be a positive number")
      .integer("Phone Number must be an integer"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    address: Yup.string().required("Address is required"),
    bio: Yup.string().required("Bio is required"),
  });

  const formik = useFormik({
    initialValues: {
      practice_name: "",
      full_name: "",
      phone_number: "",
      email: "",
      bio: "",
      address: "",
      working_hours: [
        {
          day: "",
          start_time: "",
          end_time: "",
          enabled: true,
        },
      ],
    },
    validationSchema: validationSchema,

    onSubmit: handleSubmit,
  });

  const hanldeAddWorkField = () => {
    formik.setFieldValue("working_hours", [
      ...formik.values.working_hours,
      {
        day: "",
        start_time: "",
        end_time: "",
        enabled: true,
      },
    ]);
  };

  const handleDeleteWorkField = (index) => {
    const newWorkingHours = [...formik.values.working_hours];
    newWorkingHours.splice(index, 1);
    formik.setFieldValue("working_hours", newWorkingHours);
  };

  const hanldemediaUpload = async (file, media) => {
    const formData = new FormData();
    formData.append("path", "userProfile");
    formData.append("media", file);

    try {
      const resp = await makeHttpRequest("post", "uploadAdminMedia", formData);
      // console.log("resp", resp);
      if (media === "logo") {
        setCliniclogoPath(resp?.path);
      }
      if (media === "image") {
        setClinicImage(resp?.path);
      }
    } catch (error) {
      // console.log('Network Error.');
      // toast.error('Network error.')
    }
  };

  const searching = (value) => {
    // Update the state with the new search term
    setSearchTerm(value);

    // Call the debounced searching function
  };

  const getBranch = async () => {
    try {
      const Resp1 = await makeHttpRequest("get", `getBranchListing`, null, {
        clinic_id: clinicId,
        name: searchTerm,
      });
      console.log(Resp1);
      setBranchData(Resp1?.data);

      const params = {
        clinic_id: clinicId,
      };

      const resp = await makeHttpRequest(
        "get",
        "branchListForDentistForm",
        null,
        params
      );
      // console.log('gsdhgd', resp);
      setBranchListing(resp?.data);
    } catch (e) {
      // toast.error('Network error.')
    }
  };

  useEffect(() => {
    if (clinicId) {
      const timeoutId = setTimeout(() => {
        getBranch();
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [clinicId, searchTerm]);

  const getProviderListing = async (branchId) => {
    if (branchId != null) {
      const params = {
        branch_id: branchId,
      };
      try {
        const resp = await makeHttpRequest(
          "get",
          "getDentistListing",
          null,
          params
        );
        // console.log(resp)
        setProviderListing(resp?.data);
      } catch (error) {
        // console.log(error)
        // toast.error('Network error.')
      }
    }
  };

  useEffect(() => {
    let temp = null;
    getProviderListing(temp);
  }, []);
  // console.log(ProviderListing)
  useEffect(() => {
    if (selector || inp) {
      const timeoutId = setTimeout(() => {
        getProviderListing1(selector, inp);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [inp, selector]);

  const getProviderListing1 = async (id, input) => {
    const params = {
      branch_id: id,
      name: input,
    };
    try {
      const resp = await makeHttpRequest(
        "get",
        "getDentistListing",
        null,
        params
      );
      // console.log(resp)
      setProviderListing(resp?.data);
    } catch (error) {
      console.log(error);
      // toast.error('Network error.')
    }
  };

  const searchingFun = (id, input) => {
    setInp(input);
    setSelector(id);
  };

  const handlePopupOpen = () => {
    setShowPopup(true);
  };
  const [services, setServices] = React.useState([]);
  const [serData, setSerData] = useState("");
  const [val, setVal] = React.useState("");
  // const [canVal, setCanVal] = useState("");
  // const [otherVal, setOtherVal] = useState("");
  const [insurancess, setInsurancess] = useState([]);
  const [insData, setInsData] = useState("");

  const getServices = (services1) => {
    setServices(services1);
  };

  const getInsurance = (insurances) => {
    setInsurancess(insurances);
  };

  const insuranceHandler = (val, type) => {
    if (type === "canadian") {
      setInsData(val);
      // setCanVal(val);
    } else {
      setInsData(val);
      // setOtherVal(val);
    }
  };

  const serviceHandler = (val) => {
    // if (serData?.includes(val)) {
    //   return;
    // }
    console.log(val);
    setSerData(val);
    setVal(val);
  };
 
  // function generateHoursOptions(selectedStartTime) {
  //   const options = [];
  //   for (let i = 0; i < 24; i++) {
  //     const hour = i.toString().padStart(2, '0'); 
  //     const time = `${hour}:00`; 
  //     const isDisabled = selectedStartTime && time <= selectedStartTime;
  //     options.push(
  //       <MenuItem key={i} value={time} disabled={isDisabled}>
  //         {time}
  //       </MenuItem>
  //     );
  //   }
  //   return options;
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <form onSubmit={formik.handleSubmit}>
                {/* ----Clinic-Details--- */}

                {currentStep === 1 && (
                  <SoftBox
                    mb={3}
                    component="form"
                    role="form"
                    className="appointment_form"
                  >
                    <SoftTypography variant="h6">Clinic Details</SoftTypography>

                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Enter The Practice Name{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Practice Name"
                            {...formik.getFieldProps("practice_name")}
                          />
                          {formik.touched.practice_name &&
                          formik.errors.practice_name ? (
                            <div className="form-error">
                              {formik.errors.practice_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Enter Your Name{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Enter Name"
                            {...formik.getFieldProps("full_name")}
                          />
                          {formik.touched.full_name &&
                          formik.errors.full_name ? (
                            <div className="form-error">
                              {formik.errors.full_name}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Mobile Number{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="text"
                            placeholder="Number"
                            name="phone_number"
                            {...formik.getFieldProps("phone_number")}
                          />
                          {formik.touched.phone_number &&
                          formik.errors.phone_number ? (
                            <div className="form-error">
                              {formik.errors.phone_number}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Email ID <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="email"
                            placeholder="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="form-error">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Upload Clinic Background Image{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>

                          <SoftInput
                            type="file"
                            onChange={(event) =>
                              hanldemediaUpload(event.target.files[0], "image")
                            }
                            placeholder="upload"
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Upload Clinic Logo{" "}
                              <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            type="file"
                            onChange={(event) =>
                              hanldemediaUpload(event.target.files[0], "logo")
                            }
                            placeholder="upload"
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Bio<span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          <SoftTextArea
                            placeholder={"Enter Your Bio"}
                            rows={4}
                            cols={4}
                            name="bio"
                            id="bio"
                            {...formik.getFieldProps("bio")}
                          />
                          {formik.touched.bio && formik.errors.bio ? (
                            <div className="form-error">
                              {formik.errors.bio}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>

                      <Grid item md={6} xs={12} lg={6} xl={6}>
                        <SoftBox>
                          <SoftBox mb={1} ml={0.5}>
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Add Address <span className="text-danger">*</span>
                            </SoftTypography>
                          </SoftBox>
                          {/* <SoftTextArea
                            placeholder={"Enter Your Full Address"}
                            rows={4}
                            cols={4}
                            {...formik.getFieldProps("address")}
                          /> */}
                          <input
                            className="form-control ad-adrs-input"
                            placeholder="Your Address"
                            name="school_address"
                            type="textarea"
                            // onChange={(e) => { handleChange(e) }}
                            onFocus={handlePopupOpen}
                            onClick={handlePopupOpen}
                            ref={searchBoxRefStreet}
                          />

                          {showPopup && (
                            <div className="map-popup">
                              <GoogleMap onLoad={onMapLoadStreet}></GoogleMap>
                            </div>
                          )}
                          {formik.touched.address && formik.errors.address ? (
                            <div className="form-error">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </SoftBox>
                      </Grid>

                      {/* ********************handle images******************** */}
                      <Grid item md={12} xs={12} lg={12} xl={12}>
                        <div className="imgUploaderContainer mt-5">
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p>
                              Drag & drop images here, or click to select up to
                              6
                            </p>
                          </div>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="image-preview">
                            <div className="image-list">
                              {selectedFiles.map((file, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Preview ${index + 1}`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "150px",
                                    }}
                                  />
                                  <button
                                    className="close"
                                    type="button"
                                    onClick={() => removeImage(index)}
                                  >
                                    x
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    <SoftBox mb="3" className="clinic_ser_&_insur">
                      <Grid container spacing={4}>
                        <Grid item md={6} lg={6} xl={6}>
                          <SoftTypography variant="h6" mb={2}>
                            Add Services
                          </SoftTypography>
                          <SoftBox className="add_services_sec">
                            <Services
                              setServicesToParent={getServices}
                              serData={serData}
                              getServicesData={getServicesData}
                              isDisabledInput={true}
                            />
                            <div
                              className="selectWrpSm"
                              style={{ width: "100%", margin: "1rem 0" }}
                            >
                              <SuiSelect
                                className="filter selctServc mb-4"
                                placeholder="Select Services"
                                options={[
                                  { value: "Fillings", label: "Fillings" },
                                  { value: "Crowns", label: "Crowns" },
                                  { value: "Root Canal", label: "Root Canal" },
                                  // {
                                  //   value: "Wisdom teeth Removal",
                                  //   label: "Wisdom teeth Removal",
                                  // },
                                  {
                                    value: "Teeth Whitening",
                                    label: "Teeth Whitening",
                                  },
                                  {
                                    value: "Dental Bridge",
                                    label: "Dental Bridge",
                                  },
                                  // {
                                  //   value: "Tooth Extraction",
                                  //   label: "Tooth Extraction",
                                  // },
                                  {
                                    value: "Checkup & Cleanings",
                                    label: "Checkup & Cleanings",
                                  },
                                  // {
                                  //   value: "Dental Bonding",
                                  //   label: "Dental Bonding",
                                  // },
                                  {
                                 value: "Braces",
                                 label: "Braces",
                                  },
                                  
                                  {
                                    value: "Wisdom tooth Extraction",
                                    label: "Wisdom tooth Extraction",
                                  },
                                  {
                                    value: "Veneers",
                                    label: "Veneers",
                                  },
                                  {
                                    value: "Invisalign",
                                    label: "Invisalign",
                                  },
                                  {
                                    value: "Dental Implants",
                                    label: "Dental Implants",
                                  },
                                  {
                                    value: "Composite Bonding",
                                    label: "Composite Bonding",
                                  },
                                  {
                                    value: "Mouth Guards",
                                    label: "Mouth Guards",
                                  },
                                  {
                                    value: "Dentures",
                                    label: "Dentures",
                                  },
                                ]}
                                value={val || "Fillings"}
                                onChange={serviceHandler}
                              />
                            </div>
                          </SoftBox>
                        </Grid>
                        <Grid item md={6} lg={6} xl={6}>
                          <SoftTypography variant="h6" mb={2}>
                            Add Insurance
                          </SoftTypography>
                          <SoftBox className="add_insurances">
                            <InsuranceList
                              setServicesToParent={getInsurance}
                              serData={insData}
                              getInsurancesData={getInsurancesData}
                            />
                            <SoftBox display="flex" alignItems="center" gap={1}>
                              <div
                                className="selectWrpSm"
                                style={{ width: "100%", margin: "1rem 0" }}
                              >
                                <SuiSelect
                                  className="filter selctServc mb-4"
                                  placeholder="Select Services"
                                  options={[
                                    {
                                      value: "Canadian Insurances",
                                      label: "Canadian Insurances",
                                      disabled: true,
                                    },

                                    {
                                      value: "Manulife",
                                      label: "Manulife",
                                    },
                                    {
                                      value: "Sun Life",
                                      label: "Sun Life",
                                    },
                                    {
                                      value: "Canada Life",
                                      label: "Canada Life",
                                    },
                                    {
                                      value: "Blue Cross",
                                      label: "Blue Cross",
                                    },
                                    {
                                      value: "Desjardins",
                                      label: "Desjardins",
                                    },
                                    {
                                      value: "Green Shield",
                                      label: "Green Shield",
                                    },
                                    {
                                      value: "Industrial Alliance",
                                      label: "Industrial Alliance",
                                    },
                                    {
                                     value: "CDCP",
                                     label: "CDCP",
                                     },
                                  ]}
                                  // value={canVal || "Canada Life"}
                                  defaultValue="Canadian Insurances"
                                  onChange={(selected) =>
                                    insuranceHandler(selected, "canadian")
                                  }
                                />
                              </div>
                              <div
                                className="selectWrpSm 123"
                                style={{ width: "100%", margin: "1rem 0" }}
                              >
                                <SuiSelect
                                  className="filter selctServc mb-4 "
                                  placeholder="Select Insurance"
                                  options={[
                                    {
                                      value: "Others",
                                      label: "Others",
                                      disabled: true,
                                    },

                                    { value: "Anthem", label: "Anthem" },
                                    { value: "Humana", label: "Humana" },
                                    {
                                      value: "Ameritas",
                                      label: "Ameritas",
                                    },
                                    {
                                      value: "Aetna",
                                      label: "Aetna",
                                    },
                                    {
                                      value: "Cigna",
                                      label: "Cigna",
                                    },
                                    {
                                      value: "Delta Dental",
                                      label: "Delta Dental",
                                    },
                                    {
                                      value: "Guardian",
                                      label: "Guardian",
                                    },
                                    {
                                      value: "Medicaid",
                                      label: "Medicaid",
                                    },
                                    {
                                      value: "Metlife",
                                      label: "Metlife",
                                    },
                                    {
                                      value: "United Health care",
                                      label: "United Health care",
                                    },
                                  ]}
                                  // value={otherVal}
                                  defaultValue="Others"
                                  onChange={(selected) =>
                                    insuranceHandler(selected, "other")
                                  }
                                />
                              </div>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                    <Grid item md={6} xs={12} lg={6} xl={6} style={{ flex: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {" "}
                          {/* Adjust the xs value as needed */}
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Modern <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={[
                                { value: "high", label: "High" },
                                { value: "medium", label: "Medium" },
                                { value: "low", label: "Low" },
                              ]}
                              value={modernRating}
                              onChange={(event) => setModernRating(event)}
                            />
                          </SoftBox>
                        </Grid>
                        <Grid item xs={6}>
                          {" "}
                          {/* Adjust the xs value as needed */}
                          <SoftBox>
                            <SoftBox mb={1} ml={0.5}>
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                Flexibility{" "}
                                <span className="text-danger">*</span>
                              </SoftTypography>
                            </SoftBox>
                            <SuiSelect
                              className="filter selctServc mb-4"
                              placeholder="Select Services"
                              options={[
                                { value: "high", label: "High" },
                                { value: "medium", label: "Medium" },
                                { value: "low", label: "Low" },
                              ]}
                              value={flexibilityRating}
                              onChange={(event) => setFlexibilityRating(event)}
                            />
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </Grid>

                    <SoftBox
                      mt={3}
                      component="form"
                      role="form"
                      className="working_hours"
                    >
                      <SoftBox
                        display="flex"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SoftTypography variant="h6">
                          Add Working Hours Details
                        </SoftTypography>
                        <SoftButton
                          variant="contained"
                          size="small"
                          onClick={hanldeAddWorkField}
                          color="info"
                        >
                          Add New
                        </SoftButton>
                      </SoftBox>

                      {/* ****************************working details***************************/}
                      {formik.values.working_hours?.map((obj, index) => (
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12} lg={4} xl={4}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Select Day{" "}
                                  <span className="text-danger">*</span>
                                </SoftTypography>
                              </SoftBox>
                              <SuiSelect
                                className="filter"
                                placeholder="Select Day"
                                options={[
                                  { value: "Monday", label: "Monday" },
                                  { value: "Tuesday", label: "Tuesday" },
                                  { value: "Wednesday", label: "Wednesday" },
                                  { value: "Thursday", label: "Thursday" },
                                  { value: "Friday", label: "Friday" },
                                  { value: "Saturday", label: "Saturday" },
                                  { value: "Sunday", label: "Sunday" },
                                ]}
                                defaultValue="Monday"
                                value={formik.values.working_hours[index]?.day}
                                onChange={(selectedValue) => {
                                  if (
                                    !formik?.values?.working_hours?.some(
                                      (wh) => wh?.day === selectedValue
                                    )
                                  ) {
                                    const newWorkingHours = [
                                      ...formik.values.working_hours,
                                    ];
                                    newWorkingHours[index] = {
                                      ...newWorkingHours[index],
                                      day: selectedValue,
                                    };
                                    formik.setFieldValue(
                                      "working_hours",
                                      newWorkingHours
                                    );
                                  } else {
                                    toast.error("Already exist.");
                                  }
                                }}
                              />
                            </SoftBox>
                          </Grid>
                          <Grid item md={2} xs={12} lg={2} xl={2}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  From <span className="text-danger">*</span>
                                </SoftTypography>
                              </SoftBox>
                             
                              <Select
                              {...formik.getFieldProps(
                                  `working_hours[${index}].start_time`
                                )}
                            
                            >
                             {generateHoursOptions()}
                           </Select>
                               {/* <SoftInput
                                type="time"
                                max='00:59' min='00:00'
                                {...formik.getFieldProps(
                                  `working_hours[${index}].start_time`
                                )}
                              /> */}
                         
                            </SoftBox>
                          </Grid>
                          <Grid item md={2} xs={12} lg={2} xl={2}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  To <span className="text-danger">*</span>
                                </SoftTypography>
                              </SoftBox>
                              <Select
                              {...formik.getFieldProps(
                                  `working_hours[${index}].end_time`
                                )}
                            
                            >
                             {generateHoursOptions(formik.values.working_hours[index].start_time)}
                           </Select>
                              {/* <SoftInput
                                type="time"
                                {...formik.getFieldProps(
                                  `working_hours[${index}].end_time`
                                )}
                                format="HH:mm"
                              /> */}
                            </SoftBox>
                          </Grid>
                          <Grid item md={1} xs={12} lg={1} xl={1}>
                            <SoftBox>
                              <SoftBox mb={1} ml={0.5}>
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                  Enable <span className="text-danger">*</span>
                                </SoftTypography>
                              </SoftBox>
                              {console.log(
                                "work------>",
                                formik?.values?.working_hours
                              )}
                              <Switch
                                id={index}
                                checked={
                                  formik?.values?.working_hours[index]
                                    ?.enabled || false
                                }
                                onChange={(e) => {
                                  
                                  const newWorkingHours = [
                                    ...formik.values.working_hours,
                                  ];
                                  newWorkingHours[index] = {
                                    ...newWorkingHours[index],
                                    enabled: e.target.checked,
                                  };
                                  formik.setFieldValue(
                                    "working_hours",
                                    newWorkingHours
                                  );
                                }}
                              />
                            </SoftBox>
                          </Grid>
                          {index != 0 ? (
                            <Grid item md={2} xs={12} lg={2} xl={2}>
                              <SoftBox className="icon-cntner">
                                <SoftBox mb={1} ml={0.5}>
                                  <SoftTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Clear
                                  </SoftTypography>
                                </SoftBox>
                                <IoMdCloseCircle
                                  onClick={() => handleDeleteWorkField(index)}
                                />
                              </SoftBox>
                            </Grid>
                          ) : null}
                        </Grid>
                      ))}
                    </SoftBox>
                  </SoftBox>
                )}

                {/* ---Add Branches---  */}
                {currentStep === 2 && (
                  <SoftBox mb={3} className="provider_details">
                    <SoftTypography mb={3} variant="h6">
                      Location Grid
                    </SoftTypography>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftBox
                        className="table_header"
                        display="flex"
                        alignItems="center"
                      >
                        <SoftBox
                          ml={2}
                          className="filter_box"
                          display="flex"
                          gap="15px"
                        >
                          <SoftInput
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                            onChange={(e) => searching(e.target.value)}
                            value={searchTerm}
                          />

                          <SuiSelect
                            className="filter"
                            placeholder="Select Clinic"
                            options={[
                              {
                                value: "Clinic",
                                label: "Clinic",
                                disabled: true,
                              },
                              ...branchListing?.map((obj) => ({
                                value: obj?.practice_name,
                                label: obj?.practice_name,
                              })),
                            ]}
                            defaultValue="Clinic"
                            onChange={(selectedValue) => {
                              // setSearchTerm(selectedValue);
                              searching(selectedValue);
                            }}
                          />
                        </SoftBox>
                      </SoftBox>
                      <SoftButton
                        onClick={handleClickOpen2}
                        variant="contained"
                        size="medium"
                        color="info"
                      >
                        Add Location
                      </SoftButton>
                    </SoftBox>

                    <SoftBox my={3}>
                      <Card>
                        <SoftBox p={2}>
                          <Grid container spacing={3}>
                        
                            {branchData.map((val) => (
                              <Grid item xs={12} md={6} xl={3}>
                                <DefaultProjectCard
                                  image={`${process.env.REACT_APP_IMAGE_URL}userProfile/${val?.clinic_image}`}
                                  label={`Location #${val?.branch_number}`}
                                  title={val?.practice_name}
                                  description={val?.bio}
                                  action={{
                                    type: "internal",
                                    route: "/addClinic",
                                    color: "info",
                                    label: "view branch",
                                  }}
                                  authors={[]}
                                  id={val?._id}
                                  setBranchData={setBranchData}
                                  clinicId={clinicId}
                                  status={
                                    val?.status == "active" ? true : false
                                  }
                                />
                                {console.log(val?._id)}
                              </Grid>
                            ))}
                          </Grid>
                        </SoftBox>
                      </Card>
                    </SoftBox>
                  </SoftBox>
                )}

                {/* ---Add providers---  */}
                {currentStep === 3 && (
                  <SoftBox mb={3} className="provider_details">
                    <SoftTypography mb={3} variant="h6">
                      Dentist Grid
                    </SoftTypography>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <SoftBox
                        className="table_header"
                        display="flex"
                        alignItems="center"
                      >
                        <SoftBox
                          ml={2}
                          className="filter_box"
                          display="flex"
                          gap="15px"
                        >
                          <SoftInput
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                            onChange={(e) => {
                              searchingFun(selector, e.target.value);
                            }}
                          />

                          <SuiSelect
                            className="filter"
                            options={[
                              {
                                value: "Branch",
                                label: "Branch",
                                disabled: true,
                              },
                              ...branchListing?.map((obj) => ({
                                value: obj?._id,
                                label: obj?.practice_name,
                              })),
                            ]}
                            defaultValue="Branch"
                            onChange={(selectedValue) => {
                              searchingFun(selectedValue, inp);
                            }}
                          />
                        </SoftBox>
                      </SoftBox>
                      <SoftButton
                        onClick={handleClickOpen}
                        variant="contained"
                        size="medium"
                        color="info"
                      >
                        Add Dentist
                      </SoftButton>
                    </SoftBox>
                    <SoftBox p={2}>
                      <Grid container spacing={3}>
                   
                        {ProviderListing?.map((obj) => (
                          <Grid item xs={12} md={6} xl={3}>
                            <DefaultProjectCard1
                              image={
                                obj?.profile_image
                                  ? process.env.REACT_APP_IMAGE_URL +
                                    "userProfile/" +
                                    obj?.profile_image
                                  : Doctor01
                              }
                              label={obj?.designation}
                              title={obj?.full_name}
                              // description="As Uber works through a huge amount of internal management turmoil."
                              action={{
                                type: "internal",
                                route: `/providerProfile/${obj?._id}`,
                                color: "info",
                                label: "View Profile",
                              }}
                              providerId={obj?._id}
                              status={obj?.status}
                              clinicId={clinicId}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </SoftBox>
                  </SoftBox>
                )}
                {open1 && (
                  <DialogProviders
                    handleClose1={handleClose1}
                    open1={open1}
                    clinicId={clinicId}
                    getProviderList={getProviderListing}
                  />
                )}

                <DialogBranches
                  handleClose2={handleClose2}
                  open2={open2}
                  id={clinicId}
                  setBranchData={setBranchData}
                  getBranchList={clinicId && getBranch}
                  key={clinicId}
                />

                <SoftBox
                  display="flex"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  {currentStep > 1 && (
                    <SoftButton
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      size="small"
                      color="info"
                      onClick={handleBack}
                    >
                      Back
                    </SoftButton>
                  )}
                  {currentStep == 2 && (
                    <SoftButton
                      variant="contained"
                      size="small"
                      type="button"
                      color="info"
                      onClick={handleNext}
                    >
                      Next
                    </SoftButton>
                  )}
                  {/* {currentStep > 1 && currentStep> 2 &&
                    (
                      <SoftButton variant="contained" size="small" type="button" color="info">
                        Finish
                      </SoftButton>
                    )} */}
                  {currentStep == 1 && (
                    <SoftButton
                      variant="contained"
                      size="small"
                      type="submit"
                      color="info"
                      // onClick={handleNext}
                    >
                      Next
                    </SoftButton>
                  )}
                  {currentStep == 3 && (
                    <SoftButton
                      variant="contained"
                      size="small"
                      type="button"
                      color="info"
                      onClick={() => navigate("/clinicManagement")}
                    >
                      Finish
                    </SoftButton>
                  )}
                </SoftBox>
              </form>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
// AIzaSyCfMyQQUWP__rcjr8c7mfm29GGBy2mfI10
