import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import TermConditions from "layouts/TermConditions";
import PrivacyPolicy from "layouts/PrivacyPolicy";
import ContactUs from "layouts/ContactUs";
// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import ViewFAQ from "layouts/FaqManagement/ViewFAQ/ViewFAQ";
import AppointmentList from "layouts/BookingManagement/AppointmentList";
import ViewAppointment from "layouts/BookingManagement/ViewAppointment";
import UserManagement from "layouts/UserManagement/UserManagement";
import ViewUserManagement from "layouts/UserManagement/ViewUserManagement";
import EditUserManagement from "layouts/UserManagement/EditUserManagement";
import ClinicManagement from "layouts/clinicManagement/ClinicManagement";
import { FaBlog, FaGifts, FaUser, FaUserCheck } from "react-icons/fa";
import { FaBookMedical } from "react-icons/fa";
import EditClinicManagement from "layouts/clinicManagement/EditClinicManagement";
import AddClinicManagement from "layouts/clinicManagement/AddClinicManagement";
import Overview from "layouts/clinicManagement/clinicProfile";
import EditAppointment from "layouts/BookingManagement/EditAppointment";
import ProviderProfile from "layouts/ProviderProfile/ProviderProfile";
import EnquiryManagement from "layouts/EnquiryManagement/EnquiryManagement";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password";

import HomeManagement from "layouts/HomeManagement";
import {
  Chat,
  ContactEmergency,
  DensitySmallOutlined,
  GifBoxOutlined,
  Help,
  Home,
  Info,
  PriceChange,
  PrivacyTip,
  QueryStatsOutlined,
} from "@mui/icons-material";
import SupportManagement from "layouts/SupportManagement";
import ForDentistManagement from "layouts/ForDentistManagement";
import AboutUsManagement from "layouts/AboutUsManagement";
import { PrivateRoute } from "utils/PrivateRoute";
import Branch from "layouts/clinicManagement/branchProfile";
import EditClinicBranch from "layouts/clinicManagement/EditClinicBranch";
import AuthOtp from "layouts/authentication/auth-otp/AuthOtp";
import Notification from "layouts/notification";
import UserSearches from "layouts/UserSearches/UserSearches";
import { MdPersonSearch } from "react-icons/md";
import ViewUserSearch from "layouts/UserSearches/ViewUserSearch";
import ChatSupport from "layouts/ChataManagement/ChatSupport";
import ClinicCharges from "layouts/ClinicCharges/ClinicCharges";
import RatingAndReview from "layouts/Raing&Reviews/RatingAndReview";
import AddReview from "layouts/Raing&Reviews/AddReview";
import EditReview from "layouts/Raing&Reviews/EditReview";
import ViewReview from "layouts/Raing&Reviews/ViewReview";
import ReviewDetail from "layouts/Raing&Reviews/ReviewDetail";
import React from "react";
import BillingCycle from "layouts/BillingCycle/index";
import GiftCardList from "layouts/RewardManagement/GiftCardList";
import AddEditGiftCard from "layouts/RewardManagement/AddEditGiftCard";
import EligibleAppointmentList from "layouts/EligibleEmployeeManagement/EligibleAppointmentList";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    noCollapse: false,
  },
  {
    name: "editClinicBranch",
    key: "editClinicBranch",
    route: "/editClinicBranch/:id",
    component: (
      <PrivateRoute>
        <EditClinicBranch />
      </PrivateRoute>
    ),
  },

  {
    type: "collapse",
    name: "User Management",
    key: "userManagement",
    route: "/userManagement",
    icon: <FaUser size="16px" />,
    component: (
      <PrivateRoute>
        <UserManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Billing Cycle",
    key: "billingCycle",
    route: "/billingCycle",
    icon: <FaBookMedical size="16px" />,
    component: <PrivateRoute><BillingCycle /></PrivateRoute>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Appointments Management",
    key: "appointmentList",
    route: "/appointmentList",
    icon: <FaBookMedical size="16px" />,
    component: (
      <PrivateRoute>
        <AppointmentList />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  {
    name: "Reward Management",
    key: "eligible",
    route: "/eligible",
    icon: <FaUserCheck size="16px" />,
    component: (
      <PrivateRoute>
        <EligibleAppointmentList />
      </PrivateRoute>
    ),
    type: "collapse",
    noCollapse: true,
  },
  {
    name: "Landing Page Management",
    key: "landingPage",
    route: "/landingPage",
    icon: <FaGifts size="16px" />,
    component: (
      <PrivateRoute>
        <GiftCardList />
      </PrivateRoute>
    ),
    type: "collapse",
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Clinic Management",
    key: "clinicManagement",
    route: "/clinicManagement",
    icon: <Office size="16px" />,
    component: (
      <PrivateRoute>
        <ClinicManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Clinic Charges",
    key: "clinicCharges",
    route: "/clinicCharges",
    icon: <PriceChange size="16px" />,
    component: (
      <PrivateRoute>
        <ClinicCharges />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Enquiry Management",
    key: "enquiryManagement",
    route: "/enquiry-Management",
    icon: <QueryStatsOutlined size="16px" />,
    component: (
      <PrivateRoute>
        <EnquiryManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "User Searches",
    key: "userSearches",
    route: "/userSearches",
    icon: <MdPersonSearch size="19px" />,
    component: <UserSearches />,
    noCollapse: true,
  },

  {
    name: "viewUserSearch",
    key: "viewUserSearch",
    route: "/view-UserSearch/:id",
    icon: <SpaceShip size="16px" />,
    component: (
      <PrivateRoute>
        <ViewUserSearch />
      </PrivateRoute>
    ),
  },

  {
    type: "collapse",
    name: "Chat Management",
    key: "chat Management",
    route: "/chat-Management",
    icon: <Chat size="16px" />,
    component: (
      <PrivateRoute>
        <ChatSupport />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    name: "viewClinic",
    key: "viewClinic",
    route: "/viewClinic/:id",
    component: (
      <PrivateRoute>
        <Overview />
      </PrivateRoute>
    ),
  },
  {
    name: "editClinic",
    key: "editClinic",
    route: "/editClinic/:id",
    component: (
      <PrivateRoute>
        <EditClinicManagement />
      </PrivateRoute>
    ),
  },
  {
    name: "addClinic",
    key: "addClinic",
    route: "/addClinic",
    component: (
      <PrivateRoute>
        <AddClinicManagement />
      </PrivateRoute>
    ),
  },

  { type: "title", title: "Content Management", key: "content-management" },
  {
    type: "collapse",
    name: "Terms & Conditions",
    key: "TermConditions",
    route: "/TermConditions",
    icon: <PrivacyTip size="16px" />,
    component: (
      <PrivateRoute>
        <TermConditions />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "PrivacyPolicy",
    route: "/PrivacyPolicy",
    icon: <PrivacyTip size="16px" />,
    component: (
      <PrivateRoute>
        <PrivacyPolicy />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "FAQ",
  //   key: "FAQ",
  //   route: "/FAQ",
  //   icon: <CustomerSupport size="16px" />,
  //   component: <PrivateRoute><FaqManagement /></PrivateRoute>,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Contact Us",
    key: "ContactUs",
    route: "/ContactUs",
    icon: <ContactEmergency size="16px" />,
    component: (
      <PrivateRoute>
        <ContactUs />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Home Management",
    key: "home-management",
    route: "/home-management",
    icon: <Home size="16px" />,
    component: (
      <PrivateRoute>
        <HomeManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Support Management",
    key: "support-management",
    route: "/support-management",
    icon: <Help size="16px" />,
    component: (
      <PrivateRoute>
        <SupportManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "For Dentists Management",
    key: "fordentist-management",
    route: "/fordentist-management",
    icon: <DensitySmallOutlined size="16px" />,
    component: (
      <PrivateRoute>
        <ForDentistManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "About Us Management",
    key: "about us management",
    route: "/aboutus-management",
    icon: <Info size="16px" />,
    component: (
      <PrivateRoute>
        <AboutUsManagement />
      </PrivateRoute>
    ),
    noCollapse: true,
  },

  // {
  //   type: "collapse",
  //   name: "Blog Management",
  //   key: "blog-management",
  //   route: "/blog-management",
  //   icon: <FaBlog size="16px" />,
  //   component: <PrivateRoute><BlogManagement /></PrivateRoute>,
  //   noCollapse: true,

  // },

  // {

  //   name: "Add Management",
  //   key: "add-blog",
  //   route: "/add-blog",
  //   icon: <FaBlog size="16px" />,
  //   component: <PrivateRoute><AddBlog /></PrivateRoute>,

  // },

  // {

  //   name: "EditBlog Management",
  //   key: "edit-blog",
  //   route: "/edit-blog",
  //   icon: <FaBlog size="16px" />,
  //   component: <PrivateRoute><EditBlog /></PrivateRoute>,

  // },

  // {

  //   name: "ViewBlog Management",
  //   key: "view-blog",
  //   route: "/view-blog",
  //   icon: <FaBlog size="16px" />,
  //   component: <PrivateRoute><ViewBlog /></PrivateRoute>,

  // },

  { type: "title", title: "Account Pages", key: "account-pages" },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="16px" />,
    component: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  {
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    // icon: <Document size="16px" />,
    component: <SignIn />,
    // noCollapse: true,
  },

  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="16px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "Forgot Password?",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: <Document size="16px" />,
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    // type: "collapse",
    name: "Enter OTP",
    key: "otp",
    route: "/authentication/otp/:email",
    // icon: <Document size="16px" />,
    component: <AuthOtp />,
    // noCollapse: true,
  },

  {
    key: "reset-password",
    route: "/authentication/reset-password/:id",
    icon: <Document size="16px" />,
    component: <ResetPassword />,
    noCollapse: true,
  },

  {
    key: "notification",
    route: "notification",
    icon: <Document size="16px" />,
    component: <Notification />,

  },





  {
    name: "viewFAQ",
    key: "viewFAQ",
    route: "/FaqManagement/ViewFAQ",
    icon: <SpaceShip size="16px" />,
    component: (
      <PrivateRoute>
        <ViewFAQ />
      </PrivateRoute>
    ),
  },

  {
    name: "viewUserManagement",
    key: "viewUserManagement",
    route: "/viewUserManagement/:id",
    icon: <SpaceShip size="16px" />,
    component: (
      <PrivateRoute>
        <ViewUserManagement />
      </PrivateRoute>
    ),
  },

  {
    name: "editUserManagement",
    key: "editUserManagement",
    route: "/editUserManagement/:id",
    icon: <SpaceShip size="16px" />,
    component: (
      <PrivateRoute>
        <EditUserManagement />
      </PrivateRoute>
    ),
  },

  {
    name: "Edit Appointment",
    key: "EditAppointment",
    route: "/editAppointment/:id",
    type: "children",
    component: (
      <PrivateRoute>
        <EditAppointment />
      </PrivateRoute>
    ),
  },

  {
    name: "View Appointment",
    key: "ViewAppointment",
    route: "/viewAppointment/:id",
    type: "children",
    component: (
      <PrivateRoute>
        <ViewAppointment />
      </PrivateRoute>
    ),
  },
  {
    name: "Edit Landing Page",
    key: "editLandingPage",
    route: "/landingPage/:id",
    type: "children",
    component: (
      <PrivateRoute>
        <AddEditGiftCard isEdit={true} />
      </PrivateRoute>
    ),
  },

  {
    name: "Edit Appointment",
    key: "EditwAppointment",
    route: "/editAppointment",
    type: "children",
    component: (
      <PrivateRoute>
        <EditAppointment />
      </PrivateRoute>
    ),
  },

  {
    name: "Provider Profile",
    key: "Provider Profile",
    route: "/providerProfile/:id",
    type: "children",
    component: (
      <PrivateRoute>
        <ProviderProfile />
      </PrivateRoute>
    ),
  },
  {
    name: "viewBranch",
    key: "viewBranch",
    route: "/viewBranch/:id",
    component: (
      <PrivateRoute>
        <Branch />
      </PrivateRoute>
    ),
  },

  {
    name: "Rating and reviews",
    key: "ratingandreviews",
    route: "rating-and-reviews/:id",
    icon: <SpaceShip size="16px" />,
    component: <RatingAndReview />,
  },

  {
    name: "Add Reviews",
    key: "add-reviews",
    route: "add-reviews",
    icon: <SpaceShip size="16px" />,
    component: <AddReview />,
  },

  {
    name: "Edit Review",
    key: "edit-review",
    route: "edit-review",
    icon: <SpaceShip size="16px" />,
    component: <EditReview />,
  },

  {
    name: "View Review",
    key: "view-review",
    route: "view-review",
    icon: <SpaceShip size="16px" />,
    component: <ViewReview />,
  },

  {
    name: "Review",
    key: "review-detail",
    route: "review-detail/:branchId/:mode/:id?",
    icon: <SpaceShip size="16px" />,
    component: <ReviewDetail />,
  },
];

export const subAdminRoutes = [
  {
    type: "collapse",
    name: "Chat Management",
    key: "chat Management",
    route: "/chat-Management",
    icon: <Chat size="16px" />,
    component: (
      <PrivateRoute>
        <ChatSupport />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  { type: "title", title: "Account Pages", key: "account-pages" },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="16px" />,
    component: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
    noCollapse: true,
  },
  // {
  //   // type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   // icon: <Document size="16px" />,
  //   component: <SignIn />,
  //   // noCollapse: true,
  // },

  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: <Document size="16px" />,
    component: <ForgotPassword />,
    noCollapse: true,
  },
];

export default routes;
