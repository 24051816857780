import React, { useState } from "react";
import { Button } from "antd";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SoftInput from "components/SoftInput";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { makeHttpRequest } from "utils/httpServices";
import SoftBadge from "components/SoftBadge";

const ActionToggle = ({ appointment,getData }) => {
    const [isOpen,setOpen] = useState(false)
    const [custom,setCustom] = useState(false)
    const [loading,setLoading] = useState(false)
    const [val,setVal] = useState(appointment?.landing_page?.offer_amount)

    const handleSend = async() => {
      console.log(isNaN(+val))
      setLoading(true)
      if(isNaN(+val)){
        toast.error("Invalid Input Detected") ;
        setOpen(false)
        return 
      }
      if(+val <= 0){
        toast.error("Amount Must be Greater than 0")
        setOpen(false)
        return 
      }

      try {
        const res = await makeHttpRequest("post","asignCustomGiftCardToUser",{
          landing_page_id: appointment?.landing_page?._id,
          appointment_id: appointment?._id,
          user_id: appointment?.user_details?._id,
          custom_amount: val
      })
      if(res?.code == 200){
        toast.success(res?.message || "Reward Sent Successfully")
        await getData();
      }else{
        toast.error(res?.message || "Network Error")
      }
      } catch (error) {
        toast.error(error?.message || "Network Error");
      }
      setLoading(false)
     setOpen(false)
    }

    
  return (
    <>
      {appointment?.gift_card_log?.status == "issued" || appointment?.gift_card_log?.status == "failed"  ? <SoftBadge
                        variant="gradient"
                        badgeContent={
                          appointment?.gift_card_log?.status == "issued" ? "Already Sent" :  appointment?.gift_card_log?.status == "failed" ? "Failed" :"Pending"
                        }
                        color={
                          appointment?.gift_card_log?.status == "issued" ? "success" :  appointment?.gift_card_log?.status == "failed" ? "error" : "warning"
                        }
                        size="xs"
                        container
                      /> :<Button disabled={appointment?.gift_card_log?.status == "issued"} onClick={()=>{setOpen(true)}} style={{backgroundColor:'#FF6BB5',color:"white"}}>Send</Button>}
      <Dialog
        open={isOpen}
        onClose={()=>{if(!loading) setOpen(false)}}
        style={{
          minWidth:"500px"
        }}
      
      >
        <DialogTitle style={{fontSize:"20px"}}>Send Gift Card</DialogTitle>
        <DialogContent style={{
          minWidth:"500px"
        }}>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"baseline",width:"100%",gap:"10px"}}>

         <SoftInput disabled={!custom} value={val} onChange={(e)=>{setVal(e.target.value)}} type="number" placeholder="Enter Custom Amount " style={{marginTop:"7px"}}/>
         <Button size="large" disabled={loading} style={{height:"100%",fontSize:"16px"}} onClick={()=>{setCustom(!custom)}}>Edit</Button>

          </div>
        </DialogContent>
        <DialogActions style={{paddingBottom:"20px",display:"flex",gap:"6px",paddingTop:"20px",paddingRight:"25px"}}>
          <Button size="large"  disabled={loading}  onClick={()=>{if(!loading) setOpen(false)}}>Cancel</Button>
          <Button size="large" disabled={loading} onClick={()=>{handleSend()}} style={{backgroundColor:"#FF6BB5",color:"white"}}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionToggle;
