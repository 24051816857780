// @mui material components

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";


// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Soft UI Dashboard React components
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { makeHttpRequest } from "utils/httpServices";
import { useEffect } from "react";
import { toast } from "react-toastify";


function ContactUs() {

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!formik.values.email || !formik.values.phone_number || !formik.values.facebook || !formik.values.instagram ||
            !formik.values.youtube || !formik.values.twitter || !formik.values.tiktok){
                toast.info("All fields are manadatory.")
                return;
            }

        const obj = {
            email: formik.values.email,
            phone: formik.values.phone_number,
            facebook: formik.values.facebook,
            instagram: formik.values.instagram,
            youtube: formik.values.youtube,
            x: formik.values.twitter,
            tik_tok: formik.values.tiktok,
        }
        try {
            const resp = await makeHttpRequest('put', 'addContactDetails', obj);
            toast.success('Added successfully.');
            
        } catch (e) {
            console.log(e)
           toast.error('Network Error.');
            
        }
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email Name is required'),
        phone_number: Yup.string().required('Phone number Name is required'),
        facebook: Yup.string().required('Facebook is required'),
        instagram: Yup.string().required('Instagram is required'),
        youtube: Yup.string().required('YouTube is required'),
        twitter: Yup.string().required('Twitter is required'),
        tiktok: Yup.string().required('Tiktok is required'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            phone_number: '',
            facebook: '',
            instagram: '',
            youtube: '',
            twitter: '',
            tiktok: ''
        },
        validationSchema: validationSchema,
    });

    const getData = async () => {
        try {

            const resp = await makeHttpRequest('get', 'getContactDetails');
            formik.setValues({
                email: resp?.data?.email || '',
                phone_number: resp?.data?.phone || '',
                facebook: resp?.data?.facebook || '',
                instagram: resp?.data?.instagram || '',
                youtube: resp?.data?.youtube || '',
                twitter: resp?.data?.x || '',
                tiktok: resp?.data?.tik_tok || '',
            })

        } catch (error) {
           toast.error('Network Error.')
        }
    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <DashboardLayout>
            <DashboardNavbar />

            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox component="form" role="form" className="CntForm">
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Email
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="email" placeholder="Email" name='email'
                                    {...formik.getFieldProps('email')} />
                                {
                                    formik.touched.email && formik.errors.email ? <div className="form-error">{formik.errors.email}</div> : null
                                }
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Phone Number
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="tel" placeholder="Phone number" name='phone_number'
                                    {...formik.getFieldProps('phone_number')} />
                                {
                                    formik.touched.phone_number && formik.errors.phone_number ? <div className="form-error">{formik.errors.phone_number}</div> : null
                                }
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Facebook
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="url" placeholder="Your facebook link" name='facebook'
                                    {...formik.getFieldProps('facebook')} />
                                {
                                    formik.touched.facebook && formik.errors.facebook ? <div className="form-error">{formik.errors.facebook}</div> : null
                                }
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Instagram
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="url" placeholder="Your Instagram link" name='instagram'
                                    {...formik.getFieldProps('instagram')} />
                                {
                                    formik.touched.instagram && formik.errors.instagram ? <div className="form-error">{formik.errors.instagram}</div> : null
                                }
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Youtube
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="url" placeholder="Your youtube link" name='youtube'
                                    {...formik.getFieldProps('youtube')} />
                                {
                                    formik.touched.youtube && formik.errors.youtube ? <div className="form-error">{formik.errors.youtube}</div> : null
                                }
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Twitter
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="url" placeholder="Your youtube link" name='twitter'
                                    {...formik.getFieldProps('twitter')} />
                                {
                                    formik.touched.twitter && formik.errors.twitter ? <div className="form-error">{formik.errors.twitter}</div> : null
                                }
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftBox mb={1} ml={0.5}>
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        Tiktok
                                    </SoftTypography>
                                </SoftBox>
                                <SoftInput type="url" placeholder="Your youtube link" name='twitter'
                                    {...formik.getFieldProps('tiktok')} />
                                {
                                    formik.touched.tiktok && formik.errors.tiktok ? <div className="form-error">{formik.errors.tiktok}</div> : null
                                }
                            </SoftBox>



                            <SoftBox display="flex" justifyContent="center" alignItems="center" mt={4} mb={1}>
                                <SoftButton className="w-100" variant="contained" color="info" type='submit' onClick={handleSubmit}>
                                    Update
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>

            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default ContactUs;
