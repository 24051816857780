import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Grid, Card, FormHelperText } from "@mui/material";
import { Select } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { makeHttpRequest } from "utils/httpServices";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const { Option } = Select;

const AddEditGiftCard = ({ isEdit }) => {
  const [brands, setBrands] = useState([]);
  const validationSchema = Yup.object({
    offerTitle: Yup.string().required("Offer Title is required"),
    offerDescription: Yup.string().required("Offer Description is required"),
    platformType: Yup.string().required("Platform Type is required"),
    offerCode: Yup.string().required("Offer Code is required"),
    validity: Yup.date().required("Offer Validity is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    offerAmount: Yup.number()
      .required("Offer Amount is required")
      .positive("Must be a positive number").max(500,"Amount must be less than 500"),
      
  });

  const getBrands = async () => {
    try {
      const data = await makeHttpRequest("get", "brands");
      setBrands(data?.brands || []);
    } catch (error) {
      setBrands([]);
    }
  };
  useEffect(() => {
    getBrands();

    if (paramsData.id) {
      fetchRewardById(paramsData.id);
    }
  }, []);
  const formik = useFormik({
    initialValues: {
      platformType: "",
      offerTitle: "",
      offerDescription: "",
      offerAmount: "",
      validity: "",
      offerCode: "",
      subject: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (values) {
        handleSubmitReward(values);
      }
    },
  });

  const handleSelectChange = (value) => {
    formik.setFieldValue("platformType", value);
  };

  const paramsData = useParams();

  const fetchRewardById = async (id) => {
    try {
      const res = await makeHttpRequest("get", `landing/${id}`);
      console.log(res);
      if (res.code == 200) {
        formik.setValues({
          platformType: res?.data?.platform_type,
          offerTitle: res?.data?.offer_title,
          offerDescription: res?.data?.offer_description,
          offerAmount: res?.data?.offer_amount,
          validity: res?.data?.offer_validity,
          offerCode: res?.data?.brand_code,
          subject: res?.data?.email_subject,
          message: res?.data?.email_message,
        });
        console.log("Fetch");
      }
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const handleSubmitReward = async (values) => {
    try {
      if (isEdit) {
        const res = await makeHttpRequest(
          "put",
          `updateLandingPage/${paramsData.id}`,
          {
            offer_title: values.offerTitle,
            offer_description: values.offerDescription,
            brand_code: values.offerCode,
            platform_type: values.platformType,
            offer_amount: values.offerAmount,
            offer_validity: values.validity,
            email_subject: values.subject,
            email_message: values.message,
          }
        );
        if (res.code == 200) {
          toast.success("Giftcard Updated Successfully");
          formik.resetForm();
          setTimeout(() => {
            window.location.href = "/giftcard";
          }, 600);
        } else {
          toast.error(res.message);
        }
      } else {
        const res = await makeHttpRequest("post", "createLandingPage", {
          offer_title: values.offerTitle,
          offer_description: values.offerDescription,
          brand_code: values.offerCode,
          platform_type: values.platformType,
          offer_amount: values.offerAmount,
          offer_validity: values.validity,
          email_subject: values.subject,
          email_message: values.message,
        });
        if (res.code == 200) {
          toast.success("Reward Added Successfully");
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      }
    } catch (error) {
      toast.error(error.message || "Failed to Update");
      console.log("Error : ", error);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <h4>{isEdit ? "Edit" : "Create"} Landing Page</h4>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log(formik.values, formik.errors);
                  formik.handleSubmit();
                }}
              >
                <Grid container spacing={2}>
                  {/* Offer Title */}
                  <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Offer Title <span className="text-danger">*</span>
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Offer Title"
                        {...formik.getFieldProps("offerTitle")}
                      />
                      {formik.touched.offerTitle &&
                        formik.errors.offerTitle && (
                          <FormHelperText error>
                            {formik.errors.offerTitle}
                          </FormHelperText>
                        )}
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Offer Description <span className="text-danger">*</span>
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Offer Description"
                        {...formik.getFieldProps("offerDescription")}
                      />
                      {formik.touched.offerDescription &&
                        formik.errors.offerDescription && (
                          <FormHelperText error>
                            {formik.errors.offerDescription}
                          </FormHelperText>
                        )}
                    </SoftBox>
                  </Grid>

                  {/* Platform Type */}
                  <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Platform Type <span className="text-danger">*</span>
                      </SoftTypography>
                      <Select
                        placeholder="Select Platform"
                        value={formik.values.platformType}
                        onChange={handleSelectChange}
                        disabled={isEdit || false}
                        onBlur={() =>
                          formik.setFieldTouched("platformType", true)
                        }
                        style={{ width: "100%" }}
                      >
                        <Option value="google">Google</Option>
                        <Option value="meta">Meta</Option>
                        <Option value="tiktok">Tiktok</Option>
                        <Option value="default">Other</Option>
                      </Select>
                      {formik.touched.platformType &&
                        formik.errors.platformType && (
                          <FormHelperText error>
                            {formik.errors.platformType}
                          </FormHelperText>
                        )}
                    </SoftBox>
                  </Grid>

                 

                  {/* Offer Amount */}
                  <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Offer Amount <span className="text-danger">*</span>
                      </SoftTypography>
                      <SoftInput
                        type="number"
                        placeholder="Offer Amount"
                        {...formik.getFieldProps("offerAmount")}
                      />
                      {formik.touched.offerAmount &&
                        formik.errors.offerAmount && (
                          <FormHelperText error>
                            {formik.errors.offerAmount}
                          </FormHelperText>
                        )}
                    </SoftBox>
                  </Grid>
                  
                  {/* <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Email Subject <span className="text-danger">*</span>
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        disabled={isEdit || false}
                        placeholder="Email Subject"
                        {...formik.getFieldProps("subject")}
                      />
                      {formik.touched.subject && formik.errors.subject && (
                        <FormHelperText error>
                          {formik.errors.subject}
                        </FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <SoftBox>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Email Message <span className="text-danger">*</span>
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        disabled={isEdit || false}
                        placeholder="Email Message"
                        {...formik.getFieldProps("message")}
                      />
                      {formik.touched.message && formik.errors.message && (
                        <FormHelperText error>
                          {formik.errors.message}
                        </FormHelperText>
                      )}
                    </SoftBox>
                  </Grid> */}
                </Grid>

                <SoftBox mt={3}>
                  <Button type="submit" variant="contained" color="primary" style={{color:"white"}}>
                    {isEdit ? "Update" : "Submit"}
                  </Button>
                </SoftBox>
              </form>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default AddEditGiftCard;
